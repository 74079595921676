import { Link } from "gatsby"
import React, { useState, useEffect } from 'react';

import logo from '../images/logo.png'
import cross_icon from '../images/x.svg'
import mobile_icon from '../images/menu.svg'
import "./header.css"

export default () => {

  function openMobileMenu() {
    const mobile_menu_icon = document.getElementById("mobile_menu");

    const mobile_menu = document.getElementById("mobile_main_menu");
    if(mobile_menu.classList.contains("opened_menu")){
      mobile_menu.classList.remove("opened_menu")
      mobile_menu_icon.src = mobile_icon
    }else{
      mobile_menu.classList.add("opened_menu")
      mobile_menu_icon.src = cross_icon
    }
      

  }

  useEffect(() => {
    document.getElementById("mobile_menu").style.zIndex = 99;
    const mobile_menu = document.getElementById("mobile_main_menu");
    const menu_toggle = document.getElementById("mobile_menu");
    document.addEventListener("click", function (e) {
      if (!mobile_menu.contains(e.target) && !menu_toggle.contains(e.target)) {
        mobile_menu.classList.remove("opened_menu")
        menu_toggle.src = mobile_icon
      }
    })
  })

  return (
    <header style={{
      display: "flex",
      alignItems: "center"
    }} >
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
      >

        <img className="logo_Navbar" src={logo} alt="logo" style={{
          marginBottom: 0
        }}></img>

      </Link>
      <div className="menu-list">
        <Link to="/" className="menu-list__item" activeClassName=" menu-list__selected">
          Inicio
            </Link>
        <Link to="/servicios" className="menu-list__item" activeClassName=" menu-list__selected">
          Servicios
            </Link>
        <Link to="/nosotros" className="menu-list__item" activeClassName=" menu-list__selected">
          Nosotros
            </Link>
        <Link to="/contacto" className="menu-list__item" activeClassName=" menu-list__selected">
          Contáctanos
            </Link>
      </div>
      <img onClick = { openMobileMenu } src={mobile_icon} alt="menu mobile" id = "mobile_menu" className="mobile_menu" />
      <div className="mobile_main_menu" id = "mobile_main_menu" >
        <Link to="/" className="menu-list__item">
          Inicio
            </Link>
        <Link to="/servicios" className="menu-list__item">
          Servicios
            </Link>
        <Link to="/nosotros" className="menu-list__item">
          Nosotros
            </Link>
        <Link to="/contacto" className="menu-list__item">
          Contáctanos
            </Link>
      </div>
    </header >
  )
}

