/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import ubi from "../images/Footer/ubi.svg"
import phone from "../images/Footer/phone.svg"
import linkedin from "../images/Footer/linkedin.svg"

import logo from "../images/logo.svg"
import logo_blanco from "../images/logo.png"

const Layout = ({ children, whiteFooter }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [href, setHref] = useState(0)
  const googleMapsQuery = "https://goo.gl/maps/fK6j6GEUbU33dnpHA"
  const wazeQuery = "https://waze.com/ul/h9g3qpn1he"

  useEffect(() => {
    // Update the document title using the browser API
    setHref(window.screen.width < 900 ? wazeQuery : googleMapsQuery)
  })

  return (
    <>
      <Header/>
      <main>{children}</main>
      <section className="footer" style = {whiteFooter ? {backgroundColor : "white"} : {}}>
        <div className="footer_contacts">
          <div className="footer_contacts__contact">
            <img src={phone} alt="telefono" />
            <p>Teléfono</p>
            <a href = "tel:(+52) 55.41.61.68.28">
            <span>(+52) 55.41.61.68.28</span>
            </a>
          </div>
          <div className="footer_contacts__contact">
            <img src={linkedin} alt="linkedin" />
            <p>Linkedin</p>
            <a href = "https://www.linkedin.com/company/veleztax/" ><span>VelezTax</span></a>
          </div>
          <div className="footer_contacts__contact">
            <img src={ubi} alt="ubicacion" />
            <p>Direccion</p>
            <a href={href}>
              <span>
                Av. Río Mixcoac 73 int. 204. Col. Insurgentes Mixcoac, CDMX
              </span>
            </a>
          </div>
        </div>
      </section>
      <footer >
        <div className="footer_menu" >
          <Link className="footer_menu__item" to="/">
            Inicio
          </Link>
          <Link className="footer_menu__item" to="/servicios">
            Servicios
          </Link>
          <Link className="footer_menu__item footer_menu__logo" to="/">
            <img style = {{marginBottom : "0px"}}src={logo_blanco} alt="logo" />
          </Link>
          <Link className="footer_menu__item" to="/nosotros">
            Nosotros
          </Link>
          <Link className="footer_menu__item" to="/contacto">
            Contáctanos
          </Link>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  whiteFooter: PropTypes.bool
}

export default Layout
